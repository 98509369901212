import React, { useEffect } from "react";
import { useLocation } from "react-router";
import {
  IonBadge,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuToggle,
  IonRow,
  IonSpinner,
  IonToggle,
} from "@ionic/react";
import {
  accessibility,
  moonOutline,
  images,
  cart as cartIcon,
  heart,
  addCircle,
  removeCircle,
  grid,
  ellipse,
  paw,
  imageOutline,
  flowerOutline,
  heartHalfOutline,
  home,
  colorPalette,
  mail,
  logoFacebook,
  logoInstagram,
  easel,
  call,
  earth,
  chatboxEllipses,
  sparkles,
} from "ionicons/icons";
import "./Menu.css";
import { AppState } from "../main.reducer";
import { ICategory } from "../../models";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  AVAILABLE_ONLY_CHANGE,
  LOAD_FAVORITES,
} from "../Pictures/pictures.actions";
import { useCart } from "../Cart/useCart";
import { useCommon } from "../../common/useCommon";
import "./MainMenu.css";
import { useCategories } from "../Pictures/useCategories";
import { DiscountBadge } from "../../components/DiscountBadge/DicsountBadge";

interface OwnProps {}

const mapStateToProps = (state: AppState) => ({
  selectedCategory: state.menu.selectedCategory as ICategory,
  availableOnly: state.pictures.availableOnly,
  favorites: state.pictures.favorites,
  cart: state.cart.cart,
});

const mapDispatchToProps = (dispatch: (action: any) => any) => ({
  setAvailableOnly: (available: boolean) =>
    dispatch({ type: AVAILABLE_ONLY_CHANGE, available }),
  loadFavorites: (favorites: number[]) =>
    dispatch({ type: LOAD_FAVORITES, favorites }),
});

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

const MainMenu = (props: AllProps) => {
  const { availableOnly, favorites } = props;

  const { zoom, setZoom, darkMode, setDarkMode, zoomMax, zoomMin } =
    useCommon();
  const { loadCategories, allCategories, selectCategory } = useCategories();
  const { cartCount } = useCart();

  const location = useLocation();

  const getLocalFavorites = (): number[] => {
    const storageString = localStorage.getItem("bpb.pictures.favorites");
    return storageString ? JSON.parse(storageString) : ([] as number[]);
  };
  const getLocalZoom = () => {
    const storageString = localStorage.getItem("bpb.zoom");
    return storageString ? JSON.parse(storageString) : 2;
  };

  useEffect(() => {
    loadCategories();
    setZoom(localStorage.getItem("bpb.zoom") ? getLocalZoom() : zoomMax);
    if (props.favorites.length === 0) {
      props.loadFavorites(getLocalFavorites());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const categoryItem = (code: string, icon: string, name: string) => (
    <IonItem
      button
      className={
        location.pathname.endsWith(code) ||
        (code === "svi" && location.pathname.endsWith("slike"))
          ? "selected"
          : undefined
      }
    >
      {icon === "images" && <IonIcon icon={images} />}
      {icon === "image-outline" && <IonIcon icon={imageOutline} />}
      {icon === "paw" && <IonIcon icon={paw} />}
      {icon === "flower-outline" && <IonIcon icon={flowerOutline} />}
      {icon === "heart-half-outline" && <IonIcon icon={heartHalfOutline} />}
      {icon === "earth" && <IonIcon icon={earth} />}
      {icon === "accessibility" && <IonIcon icon={accessibility} />}
      {icon === "sparkles" && <IonIcon icon={sparkles} />}
      <IonLabel style={{ paddingLeft: 35 }}>{name}</IonLabel>

      {icon === "sparkles" && (
        <div
          style={{
            backgroundColor: "var(--ion-color-danger)",
            borderRadius: 3,
            color: "#fff",
            fontWeight: 600,
            fontSize: "14px",
            padding: "3px 5px",
          }}
        >
          %%
        </div>
      )}
    </IonItem>
  );

  const renderCategories = (categories: ICategory[]) => {
    return (
      <>
        {categories.map((c) => (
          <IonMenuToggle key={c.name} auto-hide="false">
            <Link
              to={{ pathname: `/slike/${c.code}` }}
              style={{
                textDecoration: "none",
                color: "var(--ion-color-dark)",
              }}
              target="_self"
              onClick={() => selectCategory(c)}
            >
              {categoryItem(c.code, c.icon, c.name)}
            </Link>
          </IonMenuToggle>
        ))}
        {categories.length <= 1 && (
          <IonItem>
            <IonSpinner />
          </IonItem>
        )}
      </>
    );
  };

  const toggleAvailableOnly = () => {
    props.setAvailableOnly(!availableOnly);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    localStorage.setItem("bpb.darkMode", JSON.stringify(!darkMode));
  };

  return (
    <>
      <IonMenuToggle auto-hide="false">
        <Link
          to={{ pathname: "/" }}
          style={{ textDecoration: "none", color: "var(--ion-color-dark)" }}
        >
          <IonItem
            button
            className={location.pathname.endsWith("/") ? "selected" : undefined}
          >
            <IonIcon slot="start" icon={home}></IonIcon>
            <IonLabel>Početna strana</IonLabel>
          </IonItem>
        </Link>
        <Link
          to={{ pathname: "/o-nama" }}
          style={{ textDecoration: "none", color: "var(--ion-color-dark)" }}
        >
          <IonItem
            button
            className={
              location.pathname.endsWith("/o-nama") ? "selected" : undefined
            }
          >
            <IonIcon slot="start" icon={chatboxEllipses}></IonIcon>
            <IonLabel>O nama</IonLabel>
          </IonItem>
        </Link>
        <Link
          to={{ pathname: "/paket" }}
          style={{ textDecoration: "none", color: "var(--ion-color-dark)" }}
        >
          <IonItem
            button
            className={
              location.pathname.endsWith("/paket") ? "selected" : undefined
            }
          >
            <IonIcon slot="start" icon={mail}></IonIcon>
            <IonLabel>Sadržaj seta</IonLabel>
          </IonItem>
        </Link>
        <Link
          to={{ pathname: "/primeri" }}
          style={{ textDecoration: "none", color: "var(--ion-color-dark)" }}
        >
          <IonItem
            button
            className={
              location.pathname.endsWith("/primeri") ? "selected" : undefined
            }
          >
            <IonIcon slot="start" icon={colorPalette}></IonIcon>
            <IonLabel>Primeri</IonLabel>
          </IonItem>
        </Link>
      </IonMenuToggle>

      <IonList lines="none">
        <IonListHeader>Kategorije</IonListHeader>
        {renderCategories(allCategories)}

        <IonListHeader>Dodatna oprema</IonListHeader>
        <Link
          to={{ pathname: "/ram" }}
          style={{ textDecoration: "none", color: "var(--ion-color-dark)" }}
        >
          <IonItem
            button
            className={
              location.pathname.endsWith("/ram") ? "selected" : undefined
            }
          >
            <IonIcon slot="start" icon={easel}></IonIcon>
            <IonLabel>Blind ram i štafelaji</IonLabel>
          </IonItem>
        </Link>
      </IonList>

      {/* <IonItem>
                <div style={{ width: 46, paddingTop: 4 }}>
                    {!availableOnly && <IonIcon icon={ellipse} style={{ color: "var(--ion-color-danger-shade)", zIndex: 1, marginRight: -11 }} />}
                    <IonIcon slot="start" icon={ellipse} style={{ color: "var(--ion-color-success-shade)", zIndex: 2 }} />
                </div>
                <IonLabel>Samo na stanju</IonLabel>
                <IonMenuToggle auto-hide="false">
                    <IonToggle checked={availableOnly} onClick={toggleAvailableOnly} />
                </IonMenuToggle>
            </IonItem> */}

      <IonItem
        button
        className={
          location.pathname.endsWith("adminAvailability")
            ? "selected"
            : undefined
        }
      >
        {!availableOnly && (
          <IonIcon
            slot="start"
            icon={ellipse}
            style={{
              color: "var(--ion-color-danger-shade)",
              zIndex: 1,
              marginRight: -12,
              fontSize: 18,
            }}
          />
        )}
        <IonIcon
          slot="start"
          icon={ellipse}
          style={{
            color: "var(--ion-color-success-shade)",
            zIndex: 2,
            fontSize: availableOnly ? 24 : 18,
          }}
        />
        <IonLabel>Samo na stanju</IonLabel>
        <IonMenuToggle auto-hide="false">
          <IonToggle checked={availableOnly} onClick={toggleAvailableOnly} />
        </IonMenuToggle>
      </IonItem>

      <IonList lines="none">
        <IonListHeader>Omiljene i korpa</IonListHeader>
        <IonMenuToggle auto-hide="false">
          <Link
            to={{ pathname: "/omiljene" }}
            style={{ textDecoration: "none", color: "var(--ion-color-dark)" }}
          >
            <IonItem
              button
              className={
                location.pathname.endsWith("omiljene") ? "selected" : undefined
              }
            >
              <IonIcon slot="start" icon={heart} />
              <IonLabel>Omiljene</IonLabel>
              <IonBadge color="danger">
                {favorites.length > 0 ? favorites.length : ""}
              </IonBadge>
            </IonItem>
          </Link>
          <Link
            to={{ pathname: "/korpa" }}
            style={{ textDecoration: "none", color: "var(--ion-color-dark)" }}
          >
            <IonItem
              button
              className={
                location.pathname.endsWith("korpa") ? "selected" : undefined
              }
            >
              <IonIcon slot="start" icon={cartIcon} />
              <IonLabel>Korpa</IonLabel>
              <IonBadge color="success">
                {cartCount > 0 ? cartCount : ""}
              </IonBadge>
            </IonItem>
          </Link>
        </IonMenuToggle>
      </IonList>

      <IonList lines="none">
        <IonListHeader>Opcije</IonListHeader>
        <IonItem>
          <IonIcon slot="start" icon={grid}></IonIcon>
          <IonLabel>Veličina slika</IonLabel>
          <IonMenuToggle auto-hide="false">
            <IonIcon
              icon={addCircle}
              color={zoom > zoomMin ? "light-shade" : "light"}
              style={{ fontSize: 24, padding: "0 5px" }}
              onClick={() => setZoom(zoom - 1)}
            />
            <IonIcon
              icon={removeCircle}
              color={zoom < zoomMax ? "light-shade" : "light"}
              style={{ fontSize: 24, padding: "0 5px" }}
              onClick={() => setZoom(zoom + 1)}
            />
          </IonMenuToggle>
        </IonItem>
        <IonItem>
          <IonIcon slot="start" icon={moonOutline}></IonIcon>
          <IonLabel>Tamni mod</IonLabel>
          <IonToggle checked={darkMode} onClick={toggleDarkMode} />
        </IonItem>
      </IonList>

      <IonList lines="none">
        <IonListHeader>Kontakt</IonListHeader>
        <IonItem>
          <IonGrid>
            <IonRow>
              <IonCol style={{ textAlign: "center" }}>
                <a
                  href="https://www.facebook.com/bojenje.pobrojevima/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <IonIcon icon={logoFacebook} className="contact-icon" />
                </a>
                <a
                  href="https://www.instagram.com/bojenje_po_brojevima"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <IonIcon icon={logoInstagram} className="contact-icon" />
                </a>
                <a
                  href="mailto: bojenjepobrojevima@gmail.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <IonIcon icon={mail} className="contact-icon" />
                </a>
                <a
                  href="tel: +381628299831"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <IonIcon icon={call} className="contact-icon" />
                </a>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem className="center">
                  <IonLabel
                    style={{
                      userSelect: "text",
                      paddingLeft: 30,
                      fontSize: 20,
                    }}
                  >
                    062/8299831
                  </IonLabel>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
        <IonItem>
          <IonLabel className="center">
            <a
              href="mailto: bojenjepobrojevima@gmail.com"
              rel="noopener noreferrer"
              style={{ color: darkMode ? "#fff" : "#000" }}
              target="_blank"
            >
              bojenjepobrojevima@gmail.com
            </a>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonItem className="center">
                  <IonLabel style={{ userSelect: "text", paddingLeft: 10 }}>
                    {/* Bojenje po brojevima
                    <br />
                    PIB: 111784831
                    <br />
                    MB: 65661985 */}
                    {/* BPB 013 Vršac
                    <br />
                    PIB: 114075822
                    <br />
                    MB: 67306147 */}
                    MR Art 013 Vršac
                    <br />
                    PIB: 114551979
                    <br />
                    MB: 67668243
                  </IonLabel>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      </IonList>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
