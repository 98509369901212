// export const postExpressURL =
//   "http://www.postexpress.rs/struktura/lat/cenovnik/cenovnik-unutrasnji-saobracaj.asp";

export const PRICE_PICTURE = 1900; // TRY NOT TO USE
export const PRICE_PICTURE_KIDS = 900; // TRY NOT TO USE
export const PRICE_FRAME = 500;
export const PRICE_FRAME_ASSEMBLED = 1300;
export const PRICE_EASEL_WOOD = 800;
export const PRICE_EASEL_ALUMINIUM = 1900;
export const PRICE_SHIPPING = 350;

export const WEIGHT_PICTURE = 0.24;
export const WEIGHT_EASEL_WOOD = 0.15;
export const WEIGHT_EASEL_ALUMINIUM = 0.8;
export const WEIGHT_FRAME = 0.4;

export const ID_FRAME = 0;
export const ID_FRAME_ASSEMBLED = 177;
export const ID_EASEL_WOOD = 151;
export const ID_EASEL_ALUM = 152;

export const CODE_FRAME = "FRAME";
export const CODE_FRAME_ASSEMBLED = "FRAME-ASSEMBLED";
export const CODE_EASEL_WOOD = "EASEL-WOOD";
export const CODE_EASEL_ALUM = "EASEL-ALUMINIUM";

export const ADDITIONAL_EQUIPMENT_IDs = [
  ID_FRAME,
  ID_FRAME_ASSEMBLED,
  ID_EASEL_WOOD,
  ID_EASEL_ALUM,
];

export const KIDS_CATEGORY = 7;
export const ADDITIONAL_EQUIPMENT_CATEGORY = 8;

export const SPEC_CATEGORIES = [KIDS_CATEGORY, ADDITIONAL_EQUIPMENT_CATEGORY];

export const DELIVERY_MESSAGE =
  "Rok isporuke dostupnih motiva je do 5 radnih dana.";
